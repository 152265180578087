const onboardingSteps = new Vue({
    el: "#onboarding-steps-vue",
    name: "onboarding-steps",

    data() {
        return {
            basket: AnyBasket.inst,
            render: false,
            stepsMap: [
                { key: "signin", text: translation.get('global_sign_in'), active: false, complete: false },
                { key: "details", text: translation.get('global_deatails'), active: false, complete: false },
                { key: "payment", text: translation.get('global_payments'), active: false, complete: false },
                { key: "complete", text: translation.get('global_complete'), active: false, complete: false },
            ]
        }
    },

    computed: {
        hasBasketItems() {
            return this.basket && this.basket.itemsFlat ? !!this.basket.itemsFlat.length : false
        },
        stepsFiltered() {
            return this.hasBasketItems ? this.stepsMap : this.stepsMap.filter(el => el.key !== "payment")
        },

        steps() {
            return this.stepsFiltered.map((el, i) => {
                el.number = i + 1
                return el
            })
        }
    },

    methods: {
        setActive(number) {
            this.stepsMap.forEach((el, index) => {
                const i = index + 1

                if (i < number) {
                    el.complete = true
                }

                if (i === number) {
                    el.active = true
                }

            })
        }
    },

    mounted() {
        /** Good old ajax complete, prevent flickering!! */
        $(document).ajaxComplete((event, xhr, settings) => {
            if (settings.url === `/n/basket/own`) {
                setTimeout(() => {
                    this.render = true
                }, 500)
            }
        })
    }
})